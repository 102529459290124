import React from 'react'
import "../aboutus/about.css"
import work from '../../img/work.png'
import team from '../../img/team.png'
import Footer from '../footer/Footer'
import Testi from '../testi/Testi'
import Teame from '../team/Team'
import Team from '../ourteam/Team'
import Banner from '../banner/Banner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
const chooseUs = "https://i.ibb.co/6BJ7TXN/choose-us.jpg";
const time = "https://i.ibb.co/3kQDL4W/time.png";


const About = () => {
  return (
    <div>
      <div className='row about' >
        <div className='col-md-12'>
          <h1 className='a-head'>Who We Are!</h1>
          <p className='a-para'>At <span className='a-para-head'>Addnex</span>, we specialize in providing innovative tech and web solutions for businesses seeking to enhance their online presence. We offer comprehensive project management services to handle every aspect of website and app development, helping businesses achieve their full potential. 
            From developing products from scratch to turning ideas into reality, 
            we are committed to helping businesses succeed in the digital age.</p>
        </div>
      </div>

      <div className='row about new'>
        <div className='col-md-6'>
          <div className='about-cont'>
            <h2 className='about-head'>About Us</h2>
            <p className='about-para'>
            Welcome to <span style={{color:'#9393f5'}}>Addnex</span>! We are a team of experienced 
            professionals who are passionate about creating innovative digital solutions for businesses. 
            Our goal is to help our clients achieve their online objectives by providing them with 
            tailored services that meet their unique needs. With our expertise and commitment to excellence, we can help businesses of all 
            sizes succeed in the digital landscape.
            </p>
          </div>
        </div>
        <div className='col-md-6'>
            <img src={work} className='about-img'  alt="Agile project management for efficient software development - Addnex"/>
        </div>
      </div>

      <div className='row about '>
      <div className='col-md-6'>
            <img src={time} className='about-img time-img'  alt="Agile project management for efficient software development - Addnex"/>
        </div>
        <div className='col-md-6'>
          <div className='about-cont'>
            <h2 className='about-head'>Time Management</h2>
            <p className='about-para'>
            Addnex, a web services provider, understands the importance of time management and meeting deadlines in the fast-paced 
            technological landscape. Adhering to strict timelines ensures timely delivery of quality services to clients, 
            leading to high levels of customer satisfaction and a strong reputation for delivering reliable solutions.
            </p>
          </div>
        </div>
        
      </div>

      <div className='row about new'>
        <div className='col-md-6'>
          <div className='about-cont'>
            <h2 className='about-head'>Our Team</h2>
            <p className='about-para'>
            Our team of efficient and skilled professionals are the driving force behind our company's success, 
            delivering high-end solutions. With a commitment to excellence, our team is constantly pushing boundaries, 
            exploring innovative solutions and technologies, and staying ahead of the curve. Our team's expertise and experience 
            are the foundation of our success, making us a trusted partner for businesses seeking cutting-edge solutions.
            </p>
          </div>
        </div>
        <div className='col-md-6'>
            <img src={team} className='about-img'  alt="Agile project management for efficient software development - Addnex"/>
        </div>
      </div>


      


      <div className='row perSoftware'>
      <div className='col-md-6'>
        <h1 className='smallheadings'>Why Choose Addnex</h1>
          <br />
          <p className='perPara'>
          Choosing us means gaining access to our exceptional teams of highly skilled individuals who are at the forefront of their respective fields. We take pride in offering the best facilities and resources to support your needs. Our commitment to excellence ensures that we provide unparalleled expertise and innovation. When you choose us, you're selecting a partner dedicated to delivering top-tier solutions and fostering success. Join us in realizing your goals and experiencing the difference of working with the best.</p>
          <br /><br />
          <Link className='know-button' to="/service/Choose-Us">Know More</Link>
        </div>
        <div className='col-md-6'>
          <img src={chooseUs} alt="Addnex-choose-us" className='hrm-img' />
        </div>
      </div>

      <br />
     {/* <Teame /> */}

      <div className='row about ja' style={{marginTop:'5%'}}>
  <div className='col-md-12'>
<h1 className='smallheadings'>Testimonials</h1>
<br />
<Testi />
</div>
</div>








<div className='row foo'>
  <Footer />
</div>
    </div>
  )
}

export default About
