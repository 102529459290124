import React from 'react'
import { Parallax } from 'react-parallax'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import BlogImg from "../../img/blogs.jpg"
import "./blogs.css"
import Footer from '../footer/Footer'

const Blogs = () => {
  return (
    <>

    <Parallax bgImage={BlogImg} strength={500} style={{marginTop:'5%'}}>
    <div className='row'>
      <div className='col-md-12'>
        <h1 className='blog-page-heading'>Blogs</h1>
      </div>
    </div>
    </Parallax>
      <br />
      <br />

<Link className="blog-container" to="/single-post">
      <div className='row blog-mains'>
        <div className='col-md-8'>
          <h1 className='blog-title'>The Era Is Changing</h1>
          <h3 className='blog-author'>Author: addnex</h3>
          <p className='blog-date'>12-August-2000</p>
          <p className='blog-para'>About: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore.</p>
        </div>
        <div className='col-md-4'>
      <img src="https://i.ibb.co/vHLcf4Z/pexels-tima-miroshnichenko-5453811.jpg" className="blog-main-image" alt="" />
        </div>
      </div>
      </Link>

      <Link className="blog-container" to="/single-post">
      <div className='row blog-mains'>
        <div className='col-md-8'>
          <h1 className='blog-title'>The Era Is Changing</h1>
          <h3 className='blog-author'>Author: addnex</h3>
          <p className='blog-date'>12-August-2000</p>
          <p className='blog-para'>About: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore.</p>
        </div>
        <div className='col-md-4'>
      <img src="https://i.ibb.co/vHLcf4Z/pexels-tima-miroshnichenko-5453811.jpg" className="blog-main-image" alt="" />
        </div>
      </div>
      </Link>

      <Link className="blog-container" to="/single-post">
      <div className='row blog-mains'>
        <div className='col-md-8'>
          <h1 className='blog-title'>The Era Is Changing</h1>
          <h3 className='blog-author'>Author: addnex</h3>
          <p className='blog-date'>12-August-2000</p>
          <p className='blog-para'>About: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore.</p>
        </div>
        <div className='col-md-4'>
      <img src="https://i.ibb.co/vHLcf4Z/pexels-tima-miroshnichenko-5453811.jpg" className="blog-main-image" alt="" />
        </div>
      </div>
      </Link>

      <Link className="blog-container" to="/single-post">
      <div className='row blog-mains'>
        <div className='col-md-8'>
          <h1 className='blog-title'>The Era Is Changing</h1>
          <h3 className='blog-author'>Author: addnex</h3>
          <p className='blog-date'>12-August-2000</p>
          <p className='blog-para'>About: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore.</p>
        </div>
        <div className='col-md-4'>
      <img src="https://i.ibb.co/vHLcf4Z/pexels-tima-miroshnichenko-5453811.jpg" className="blog-main-image" alt="" />
        </div>
      </div>
      </Link>

      <div className='row foo'>
        <Footer />
      </div>
      
    </>
  )
}

export default Blogs
