export const productData = [
   
  { id: "digital-marketing",
   name: "Digital Marketing",
   alink:"www.facebook.com",
   imgsrc:"https://i.ibb.co/HH0jrk0/undraw-Marketing-re-7f1g.png" , 
   subdesc:"Addnex provides world-class digital marketing services, leveraging strategic insights to boost online visibility and brand impact. Elevate your business with Addnex – our expert team pioneers innovative digital marketing solutions for global excellence. Trust Addnex as your partner for unparalleled digital marketing, driving your brand to new heights with cutting-edge strategies." ,
   desc:"Addnex stands as a beacon of excellence in the digital marketing realm, offering top-tier services designed to catapult businesses into the online spotlight. With a strategic approach, our team crafts campaigns that not only enhance visibility but also leave a lasting impact on brand recognition. From innovative solutions to a commitment to global excellence, Addnex is the trusted partner for businesses seeking to thrive in the ever-evolving digital landscape. Elevate your brand with our expert team, dedicated to pioneering cutting-edge strategies that set you apart. Choose Addnex for unparalleled digital marketing prowess and watch your brand ascend to new heights." ,
   shortDesc:"Strategic online promotion to boost brand visibility and customer engagement.",
   bannerImg:"",
   leftImg:"https://i.ibb.co/Z2PjX0F/pexels-fauxels-3183170.jpg",
   rightText:
   "Dive into digital brilliance with Addnex, where strategic mastery meets innovative solutions. Experience triumphant online visibility that sets your brand apart in the digital realm. Choose Addnex to elevate your business to new heights, embodying excellence in every aspect of digital marketing.",
   rightHead:"Addnex: Elevate Excellence",
   alt:""

 },
    {
       id: "web-development", 
     name: "Web Development", 
     alink:"www.facebook.com" , 
     imgsrc:"https://i.ibb.co/30M4JRW/undraw-Start-building-re-xani.png" ,
    subdesc:"Addnex pioneers web development excellence, crafting bespoke solutions tailored to your business needs. Our expert team ensures seamless, responsive, and visually stunning websites that leave a lasting impression. Elevate your online presence with Addnex – where innovation meets precision in web development services.",
    desc:"Addnex excels in web development, delivering tailored solutions that blend precision and innovation seamlessly. Our team, adept at marrying technical expertise with creative flair, crafts responsive and visually striking websites that go beyond meeting business needs. Each project is a testament to our commitment to excellence, ensuring your online platform not only functions flawlessly but captivates users. Trust in Addnex to turn your ideas into engaging and user-friendly websites that leave a lasting impression. Elevate your online presence with our comprehensive web development services, setting your brand apart in the digital landscape. At Addnex, excellence is not just a goal – it's a standard we consistently achieve.",
    shortDesc:
    "Creating dynamic websites and applications for enhanced online experiences and functionality.",
    bannerImg:"",
    leftImg:"https://i.ibb.co/7NGymRD/pexels-cottonbro-studio-5082579.jpg",
    rightText:"Discover innovative web solutions with Addnex, where precision in development meets a commitment to excellence. Our team seamlessly integrates technical expertise and creative flair, crafting websites that surpass business needs. Experience the perfect blend of functionality and aesthetics as Addnex transforms ideas into captivating online platforms. Elevate your digital presence with our comprehensive web development services, setting your brand apart in the competitive online landscape.",
    rightHead:"Digital Excellence Unleashed",
    alt:""


   },
    { 
      id: "app-development", 
    name: "App Development", 
    alink:"www.facebook.com", 
    imgsrc:"https://i.ibb.co/1zyn6Fs/undraw-Mobile-application-re-13u3.png" , 
    subdesc:"Addnex pioneers app development, offering cutting-edge solutions tailored to your business goals. Our expert team combines technical proficiency with creative insight, crafting seamless and user-centric mobile applications. Trust Addnex to transform your app ideas into reality, ensuring a standout presence in the dynamic digital landscape.",
    shortDesc:"Crafting mobile apps for seamless user experiences and digital innovation.",
    bannerImg:"",
    leftImg:"https://i.ibb.co/wcHbP4J/pexels-cottonbro-studio-5053848.jpg",
    rightText:"Addnex is your go-to partner for innovative app solutions, seamlessly blending technical expertise and creative prowess. Our expert team crafts tailored mobile applications, ensuring a user-centric experience that sets your brand apart. Trust Addnex to transform your app ideas into reality, delivering excellence in every aspect of development. Elevate your digital presence with our top-notch app development services.",
    rightHead:"Tailored App Development",
    alt:"",
    desc:"Addnex leads the charge in innovative app solutions, delivering excellence in every facet of app development. Our expert team, proficient in the latest technologies, combines technical prowess with creative insight to craft tailored mobile applications. From concept to execution, Addnex ensures a seamless and user-centric experience for your app users. Trust us to bring your app ideas to life, leveraging our expertise to create standout digital solutions. Elevate your brand's mobile presence with Addnex, where our commitment to quality ensures your app stands out in the competitive app market. Choose Addnex for tailored app development that goes beyond expectations."



  },
    { id: "shopify-development", 
    name: "Shopify" , 
    alink:"www.facebook.com", 
    imgsrc:"https://i.ibb.co/VmbTJy5/shopify.jpg" , 
    subdesc:"Addnex specializes in delivering tailor-made Shopify development solutions, crafting e-commerce experiences that seamlessly blend creativity and functionality. Our dedicated team harnesses the power of Shopify's versatile platform to design and develop robust online stores, ensuring clients stand out in the competitive digital marketplace. From customized themes to feature-rich integrations, Addnex transforms visions into high-performing, user-friendly Shopify solutions." , 
    desc:
    "Addnex stands at the forefront of Shopify development, offering comprehensive solutions that empower businesses to thrive in the digital commerce landscape. With a deep understanding of e-commerce dynamics, our expert team meticulously crafts Shopify solutions tailored to each client's unique needs. From intuitive user interfaces to seamless payment integrations, we ensure a flawless online shopping experience. Addnex excels in developing bespoke Shopify themes, optimizing performance, and implementing scalable solutions that grow with your business. Trust us to elevate your online presence with cutting-edge Shopify development, blending innovation and functionality seamlessly.",
    shortDesc:
    "Empowering e-commerce success with the Shopify platform's robust online solutions.",
    bannerImg:"",
    leftImg:"https://i.ibb.co/hKJQZpj/pexels-karolina-grabowska-5632402.jpg",
    rightText:
    "Unlock e-commerce excellence with Addnex – your Shopify mastery solutions partner. Tailored development, seamless integration, and unmatched expertise define our approach. Elevate your online presence and thrive in the digital marketplace with Addnex at your side.",
    rightHead:
    "Shopify Mastery Solutions",
    alt:""


  },
    { 
      id: "wordpress", 
      name: "Wordpress" , 
      alink:"www.facebook.com", 
      imgsrc:"https://i.ibb.co/kMP1VC0/undraw-wordpress-utxt.png" , 
      subdesc:"Addnex excels in crafting dynamic WordPress solutions, marrying design ingenuity with powerful functionality. Our dedicated team tailors bespoke WordPress development to elevate your online presence, ensuring a seamless user experience. From custom themes to robust plugins, trust Addnex for unparalleled WordPress solutions that drive your digital success." , 
      desc:"At Addnex, we specialize in delivering top-notch WordPress development solutions that transform your digital presence. Our seasoned team combines creative design with robust functionality to create bespoke WordPress solutions tailored to your unique needs. From eye-catching custom themes to advanced plugin development, we ensure your website stands out in a competitive online landscape. With a focus on user experience, performance optimization, and responsive design, our WordPress solutions are crafted to engage and delight visitors. Count on Addnex to navigate the complexities of WordPress development, offering a seamless integration of innovation and practicality. Elevate your web presence with our comprehensive WordPress expertise, where every detail is meticulously considered for your success.",
      shortDesc:"Empowering web content management with versatile and user-friendly WordPress platform.",
      bannerImg:"",
      leftImg:"https://i.ibb.co/vqZ6TTg/pexels-markus-winkler-4152505.jpg",
      rightText:
      "Addnex redefines excellence with our WordPress development prowess, bringing innovation and precision to every project. Dive into a world of customized themes, powerful plugins, and seamless user experiences curated by our expert team. Trust Addnex to elevate your digital presence with WordPress solutions that stand out in the online realm. Unleash the full potential of your website with Addnex – where WordPress excellence meets unmatched dedication.",
      rightHead:
      "Addnex: WordPress Excellence Unleashed",
      alt:""


    },
    { id: "ui", 
    name: "Ui/Ux Design", 
    alink:"www.facebook.com", 
    imgsrc:"https://i.ibb.co/LSZQQ7D/undraw-Design-sprint-re-tke3.png" , 
    subdesc:
    "At Addnex, we redefine user experiences with world-class UI/UX design services. Our expert designers seamlessly blend creativity and functionality to craft interfaces that captivate and engage. Trust Addnex to transform your digital presence with intuitive designs that elevate user satisfaction. Experience excellence in UI/UX with Addnex – where innovation meets user-centric design." , 
    desc:
    "Addnex leads the way in providing world-class UI/UX design services that transcend the ordinary. Our seasoned design team meticulously crafts interfaces that not only look stunning but also offer an unparalleled user experience. From wireframing to prototyping, we ensure every step reflects our commitment to excellence. Addnex blends creativity and functionality seamlessly, creating designs that captivate and engage your audience. Elevate your digital presence with our user-centric approach, where each element is thoughtfully curated for maximum impact. Trust Addnex to redefine your user experiences and set your brand apart in the digital landscape.",
    shortDesc:"Designing intuitive and visually appealing user interfaces for exceptional usability.",
    bannerImg:"",
    leftImg:"https://i.ibb.co/DCJnY6M/pexels-jakub-zerdzicki-18186205.jpg",
    rightText:
    "Addnex: Elevating Digital Experiences. Our world-class UI/UX design services redefine online interactions. With Addnex, expect expertly crafted interfaces that captivate and engage users seamlessly. Trust our team to infuse creativity and functionality, making Addnex your partner in design excellence.",
    rightHead:
    "Elevating Digital Experiences Expertly",
    alt:""


  },
    { id: "quality-assurance", 
    name: "Quality Assurance", 
    alink:"www.facebook.com" , 
    imgsrc:"https://i.ibb.co/6bCVzd0/undraw-for-review-eqxk.png" , 
    subdesc:
    "At Addnex, quality assurance is paramount in our service delivery. Our dedicated QA team employs rigorous testing methodologies to ensure flawless software performance. With a commitment to excellence, Addnex guarantees top-notch quality at every stage of development. Trust us to elevate your projects with unparalleled quality assurance, making reliability and customer satisfaction our top priorities." , 
    desc:
    "At Addnex, we prioritize excellence through our rigorous quality assurance practices. Our dedicated QA team employs advanced testing methodologies to ensure the flawless functionality and reliability of your software solutions. From comprehensive testing plans to detailed bug tracking, Addnex is committed to delivering high-quality products that meet and exceed industry standards. We integrate quality assurance seamlessly into every stage of development, ensuring robust and error-free software for our clients. Trust Addnex to provide a meticulous and thorough approach to quality assurance, giving you confidence in the reliability and performance of your digital solutions. Elevate your projects with Addnex – where quality assurance meets uncompromising standards." ,
    shortDesc:
    "Ensuring product excellence through rigorous quality testing and performance analysis.",
    bannerImg:"",
    leftImg:"https://i.ibb.co/px4D2wc/pexels-towfiqu-barbhuiya-9821386.jpg",
    rightText:
    "At Addnex, excellence defines our quality assurance commitment. Our dedicated team ensures meticulous testing, guaranteeing flawless software performance. Addnex integrates thorough quality assurance seamlessly into each project, prioritizing reliability and client satisfaction. Trust us to deliver software solutions that meet the highest industry standards, where Addnex stands for unparalleled quality assurance.",
    rightHead:
    "Addnex Quality Assurance Excellence",
    alt:""


  },
    { 
      id: "management", 
    name: "Management", 
    alink:"www.facebook.com" , 
    imgsrc:"https://i.ibb.co/TtDMfJp/undraw-product-iteration-kjok.png" , 
    subdesc:
    "At Addnex, we excel in providing strategic project management for existing initiatives. Our experienced project managers meticulously analyze, plan, and execute strategies to optimize project workflows. Addnex is committed to enhancing efficiency and ensuring timely project delivery, offering comprehensive management solutions. Trust us to navigate complexities, streamline processes, and elevate the success of your ongoing projects with our expert project management services.", 
    desc:
    "Addnex takes the lead in providing robust project management solutions for existing initiatives. Our seasoned project managers leverage their expertise to conduct thorough analyses, develop strategic plans, and execute seamless workflows. With a commitment to excellence, Addnex ensures the optimal utilization of resources and the timely delivery of projects. Our comprehensive project management services encompass everything from stakeholder communication to risk mitigation, fostering success at every stage. Trust Addnex to navigate the complexities of existing projects, streamline processes, and drive efficiency, ensuring your initiatives reach their full potential. Elevate your project management experience with Addnex – where strategic planning meets successful execution.",
    shortDesc:"Efficiently leading and coordinating tasks to achieve organizational goals and objectives.",
    bannerImg:"",
    leftImg:"https://i.ibb.co/RC8d7wN/pexels-pixabay-416405.jpg",
    rightText:
    "Addnex stands out for its excellence in project management, offering tailored solutions for ongoing initiatives. Our dedicated project managers bring strategic insights and meticulous planning to streamline workflows and optimize efficiency. Addnex is committed to ensuring the successful delivery of existing projects by navigating complexities and mitigating risks effectively. Trust our comprehensive project management services to enhance communication, minimize bottlenecks, and drive the success of your ongoing initiatives. Elevate your project outcomes with Addnex – where expertise meets strategic execution.",
    rightHead:
    "Addnex Project Management Excellence",
    alt:""

  },
  { 
    id: "Choose-Us", 
  name: "Choose-Addnex", 
  alink:"www.facebook.com" , 
  imgsrc:"https://i.ibb.co/1TPLJkw/undraw-Choose-re-7d5a.png" , 
  subdesc:"At Addnex, we pride ourselves on delivering unparalleled software solutions that propel businesses to new heights. Our team of seasoned experts combines cutting-edge technology with a deep understanding of industry dynamics to craft tailored solutions that address your unique challenges. With a relentless focus on innovation, we ensure that our clients stay ahead of the curve, embracing agility and efficiency in an ever-evolving digital landscape. Choose Addnex for a seamless integration of state-of-the-art technology and a commitment to your success." , 
  desc:"In the rapidly evolving digital landscape, businesses are constantly navigating complex challenges and opportunities.At Addnex, we take pride in our ability to deliver unparalleled software solutions that drive businesses towards unprecedented success. Our dedicated team of seasoned experts, armed with a comprehensive understanding of industry dynamics, is committed to crafting tailored solutions that precisely address the unique demands of your business With an unwavering focus on innovation, we ensure that our clients not only keep pace with the ever-changing technological advancements but also stay ahead of the curve. By seamlessly integrating state-of-the-art technology, we empower businesses to embrace agility and efficiency, fostering an environment that encourages growth and resilience. Addnex stands as a beacon of reliability and excellence, offering a comprehensive suite of services that cater to the diverse needs of modern businesses. From customized software development to streamlined project management solutions, we are dedicated to propelling your business towards sustainable success. Choose Addnex for a transformative journey towards maximizing your business potential and staying ahead in the digital transformation race." ,
  shortDesc:"Efficiently leading and coordinating tasks to achieve organizational goals and objectives.",
  bannerImg:"",
  leftImg:"https://i.ibb.co/2Kd8wmG/pexels-andres-ayrton-6551415-1.jpg",
  rightText:"At Addnex, our exceptional team comprises highly skilled professionals who exhibit a seamless workflow and an unparalleled commitment to precision. With an unwavering focus on excellence, each member is meticulously trained to operate with flawless precision, ensuring a streamlined and error-free work environment. Renowned as top experts in their respective fields, our team brings an unparalleled level of expertise and ingenuity to every project. Equipped with advanced knowledge and cutting-edge insights, they lead the way in implementing the most innovative and forward-thinking solutions, setting new standards of excellence within the industry.",
  rightHead:"Meet the Flawless Experts Driving Innovation at Addnex: Unparalleled Precision and Expertise for Unmatched Solution",
  alt:""

},
{ 
  id: "know-more", 
name: "Know More", 
alink:"www.facebook.com" , 
imgsrc:"https://i.ibb.co/9rn3KVB/undraw-Personal-goals-re-iow7.png" , 
subdesc:"At our company, we are committed to staying at the forefront of technological innovation to ensure an unparalleled experience for our clients. Leveraging cutting-edge technologies, we prioritize the development of lightning-fast and highly responsive websites, providing users with seamless interactions. Simultaneously, our team is dedicated to crafting a sophisticated and optimized backend infrastructure capable of handling substantial amounts of data efficiently. By embracing advanced technologies, we strive to deliver not only speed and responsiveness but also robust solutions capable of meeting the demands of handling extensive datasets, ensuring a superior performance for our clients." ,
shortDesc:"Efficiently leading and coordinating tasks to achieve organizational goals and objectives.",
bannerImg:"",
leftImg:"https://i.ibb.co/2Kd8wmG/pexels-andres-ayrton-6551415-1.jpg",
rightText:"At Addnex, our exceptional team comprises highly skilled professionals who exhibit a seamless workflow and an unparalleled commitment to precision. With an unwavering focus on excellence, each member is meticulously trained to operate with flawless precision, ensuring a streamlined and error-free work environment. Renowned as top experts in their respective fields, our team brings an unparalleled level of expertise and ingenuity to every project. Equipped with advanced knowledge and cutting-edge insights, they lead the way in implementing the most innovative and forward-thinking solutions, setting new standards of excellence within the industry.",
rightHead:"Meet the Flawless Experts Driving Innovation at Addnex: Unparalleled Precision and Expertise for Unmatched Solution",
alt:"",
desc:"In our relentless pursuit of technological excellence, our company has adopted state-of-the-art technologies to redefine the landscape of web development. Our commitment to providing clients with exceptionally fast and responsive websites is rooted in the integration of cutting-edge frameworks and optimization techniques. By harnessing the power of advanced front-end technologies, we ensure that users experience swift interactions and seamless navigation across our platforms.Moreover, our dedication extends to the backend infrastructure, where we engineer robust systems capable of effortlessly managing vast volumes of data. Through the implementation of scalable architectures and advanced database management solutions, we guarantee not only speed but also resilience in handling extensive datasets. This approach enables us to create a foundation that is not just technologically advanced but also adaptive, ensuring our clients receive a consistently high-performance digital experience. As we continue to innovate, our focus remains on pushing the boundaries of technology to deliver websites that are not only fast and responsive but also built on a foundation of unparalleled reliability and scalability."

},

{ 
  id: "personalized-software", 
name: "Personalized Software", 
alink:"www.facebook.com" , 
imgsrc:"https://i.ibb.co/ZJZ9GVJ/undraw-Programming-re-kg9v.png" , 
subdesc:"At Addnex, our commitment to innovation goes hand in hand with our dedication to personalized solutions. We specialize in tailoring software to meet the unique requirements of upcoming projects, understanding that a one-size-fits-all approach is not always sufficient. Our team of experts collaborates closely with clients to gather insights and specifications, ensuring that the software we develop aligns seamlessly with the project's objectives. By leveraging cutting-edge technologies and adopting a client-centric approach, Addnex excels in delivering bespoke software solutions. This personalized touch not only enhances project efficiency but also guarantees that our clients receive software perfectly aligned with their specific needs and goals, fostering a collaborative and successful partnership." ,
shortDesc:"Efficiently leading and coordinating tasks to achieve organizational goals and objectives.",
bannerImg:"",
leftImg:"https://i.ibb.co/fXcZX8Y/pexels-thisisengineering-3862379.jpg",
rightText:"Our expertise shines through in Bespoke Solutions for Projects, where we specialize in crafting customized software tailored to individual project requirements. By prioritizing client collaboration and utilizing cutting-edge technologies, Addnex ensures that each solution is uniquely designed to enhance project efficiency and align seamlessly with specific goals. Choose innovation with our personalized approach to software development.",
rightHead:"Bespoke Solutions for Projects",
alt:"",
desc:"Addnex stands out in the software development landscape by offering personalized solutions tailored to the unique demands of upcoming projects. Recognizing the limitations of generic software, we prioritize a client-centric approach that involves close collaboration to understand project intricacies. Our dedicated team of experts engages in comprehensive discussions with clients, gathering insights and specifications to ensure that the software we design is a perfect fit for the project's objectives. By adopting cutting-edge technologies and embracing innovation, Addnex crafts bespoke software that not only enhances project efficiency but also aligns seamlessly with the distinct needs and goals of our clients.This commitment to personalized solutions fosters a collaborative and successful partnership, positioning Addnex as a reliable ally in navigating the complexities of modern software development. Through this tailored approach, we empower our clients with software that not only meets but exceeds their expectations, delivering solutions that are not just functional but optimized for their specific requirements."

},
{ 
  id: "human-resource", 
name: "Human Resource", 
alink:"www.facebook.com" , 
imgsrc:"https://i.ibb.co/VqnkGQT/undraw-Good-team-re-hrvm-1.png" , 
subdesc:"Addnex is not just a software development powerhouse; we extend our expertise to assist companies in recruiting top-tier leadership. Leveraging our extensive network and industry insights, we identify and connect organizations with visionary leaders who align with their strategic goals. Our commitment to excellence extends beyond technology, as we play a pivotal role in shaping successful leadership teams for sustained business success." ,
shortDesc:"Efficiently leading and coordinating tasks to achieve organizational goals and objectives.",
bannerImg:"",
leftImg:"https://i.ibb.co/xzMJnv2/pexels-resume-genius-18848929.jpg",
rightText:"Addnex embodies a 'Strategic Leadership Partnership,' leveraging its extensive network and industry expertise to aid companies in recruiting top-tier leaders. Our meticulous approach ensures a seamless alignment between leadership and strategic goals, contributing to organizational success. As a trusted partner, we empower businesses to build high-performing teams that drive sustained growth and excellence in today's competitive landscape. Choose Addnex for a strategic alliance that propels your company to new heights of achievement.",
rightHead:"Strategic Leadership Partnership",
alt:"",
desc:"At Addnex, our commitment to excellence extends beyond software development to include a pivotal role in talent acquisition for other companies. Drawing on our extensive network and profound industry insights, we specialize in identifying and connecting organizations with top-tier leaders who align seamlessly with their strategic goals. Our meticulous approach involves a deep understanding of organizational needs, ensuring a harmonious match between leadership and strategic objectives. By leveraging our expertise, we empower businesses to build high-performing leadership teams that contribute to sustained growth and success in today's competitive landscape. Choose Addnex as your strategic partner, and let us guide you towards visionary leadership that propels your company to new heights of achievement and innovation."

},
{ 
  id: "backend-development", 
name: "Backend Development", 
alink:"www.facebook.com" , 
imgsrc:"https://i.ibb.co/h1PRXth/undraw-server-cluster-jwwq.png" , 
subdesc:"Addnex excels in crafting robust and highly efficient backend architectures, leveraging cutting-edge technologies. Our expert team ensures seamless data management through scalable solutions and optimization techniques. Choose Addnex for a commitment to innovation, delivering backend systems that contribute to high-performance and scalable software solutions." ,
shortDesc:"Efficiently leading and coordinating tasks to achieve organizational goals and objectives.",
bannerImg:"",
leftImg:"https://i.ibb.co/X7r2n9X/pexels-pixabay-262367-1.jpg",
rightText:"Addnex is a leader in crafting efficient backend solutions, leveraging cutting-edge technologies and scalability expertise. Our robust architectures ensure seamless data management, contributing to the development of high-performance software applications. Choose Addnex for innovation-driven backend excellence that forms the backbone of digital success. Our commitment to technological prowess ensures a reliable and resilient foundation for your software needs.",
rightHead:"Efficient Backend Solutions.",
alt:"",
desc:"Addnex stands at the forefront of technological prowess, delivering backend architectures that epitomize robustness and efficiency. Employing cutting-edge technologies, our expert team meticulously crafts scalable solutions and employs optimization techniques, ensuring seamless data management for our clients. With a commitment to innovation, our backend systems not only handle large volumes of data but also contribute significantly to the overall efficiency and resilience of applications. Choose Addnex for a strategic partnership that guarantees high-performance and scalable software solutions, with a backend architecture designed to exceed expectations. Our expertise forms the foundation for digital success in today's dynamic technological landscape."

},
  ];

  