import React from 'react'
import "./page.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope,faGlobe,faHashtag } from '@fortawesome/free-solid-svg-icons'

import Contact from '../contact/Contact'
const ContactPage = () => {
  return (
    <>
    <div className='row banner'>
        <div className='col-md-6 c-page-left'>
        <h1 className='c-page-head'>Contact Us.</h1>
        </div>
        <div className='col-md-6 c-page-right'>
            <p className='c-page-side-heading'>Your Search Ends Here.</p>
        </div>
    </div>
    <div className='row contact-sec '>
        <div className='col-md-4 c-page-right'>
        <p className='c-page-side-heading'>Drop A Query.</p>
        </div>
        <div className='col-md-8 c-contact-page-section'>
            <Contact />
        </div>
    </div>
<br />
    <div className='row'>
        <div className='col-md-6'>
        <h1 className='c-page-head'>Connect With Us.</h1>
        </div>
        <div className='col-md-6 c-page-compart'>
                <ul className='c-page-list'>
                    <li><FontAwesomeIcon icon={faPhone} /> +91-8824991267</li>
                    <li><FontAwesomeIcon icon={faEnvelope} />  partners@addnex.com</li>
                    <li><FontAwesomeIcon icon={faGlobe} /> www.addnex.com</li>
                    <li><FontAwesomeIcon icon={faHashtag} /> twitter</li>
                </ul>
        </div>
    </div>
    </>
  )
}

export default ContactPage
