import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ent from '../../img/ent.png'

const Entrepreneurs = () => {
  return (
    <>
       
<div className='col-md-6 glass-back'>
   <br/>
   <p className='entre-para'>We offer special deals for new emerging entrepreneurs to boost their businesses and startups quickly with the best affordable rates. Our expert team ensures top-quality software development to help you achieve success and grow your venture. Take advantage of our exclusive offers to streamline your operations and stay ahead of the competition. Partner with us to turn your innovative ideas into reality with cutting-edge technology solutions.</p>
   <br /><br />
   <Link to={`/shop`} className='new-one-b '>learn More<span className='ar'><i class="fal fa-long-arrow-right"></i></span></Link>

</div> 

<div className='col-md-6'>
<img src={ent} alt="Entrepreneurs special offer" className='entre-img' />
</div>      
    </>
  )
}

export default Entrepreneurs
