import React from 'react'
import { useParams } from 'react-router-dom';
import "../service/service.css"
import Footer from '../footer/Footer'
import { productData } from '../../asset'
import Banner from '../banner/Banner';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const chooseUs = "https://i.ibb.co/6BJ7TXN/choose-us.jpg";

const Service = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const prodItem = productData.find(item => item.id === id); 
  return (
    <div>
      
      <div className='row'> 
      <div className='col-md-12'>
        <img src={prodItem.bannerImg} alt="" className='service-banner-image' />
      </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
            <img src={prodItem.imgsrc} className='hrm-img serv-img' alt=""/>
        </div>
        <div className='col-md-6 service-head bgg'>
            <h1 className='service-heading'>{prodItem.name}</h1>
            <p className='service-para'>
           {prodItem.subdesc}
           </p>

        </div>
      </div>

  <div className='row jutsu'>
      <div className='col-md-12'>
        <h3 style={{textAlign:'left',marginLeft:'5%',marginTop:'5%', fontWeight:'bolder'}}>Overview</h3>
        <p className='service-main-para'>{prodItem.desc}</p>
      </div>
      </div>

      <div className='row perSoftware'>
      <div className='col-md-6'>
          <img src={prodItem.leftImg} alt="softvos-choose-us" className='hrm-img' />
        </div>
      <div className='col-md-6'>
        <h1 className='smallheadings'>{prodItem.rightHead}</h1>
          <br />
          <p className='perPara'>
        {prodItem.rightText}
         </p>
          <br /><br />
          <Link className='know-button' to="/contact">Hire Us</Link>
        </div>
       
      </div>

      <br /><br />
      { id !== "Choose-Us" && 
  <div className='row perSoftware'>
    <div className='col-md-6'>
      <h1 className='smallheadings'>Why Choose Addnex</h1>
      <br />
      <p className='perPara'>
        Choosing us means gaining access to our exceptional teams of highly skilled individuals who are at the forefront of their respective fields. We take pride in offering the best facilities and resources to support your needs. Our commitment to excellence ensures that we provide unparalleled expertise and innovation. When you choose us, you're selecting a partner dedicated to delivering top-tier solutions and fostering success. Join us in realizing your goals and experiencing the difference of working with the best.
      </p>
      <br /><br />
      <Link className='know-button' to="/service/Choose-Us" >Know More</Link>
    </div>
    <div className='col-md-6'>
      <img src={chooseUs} alt="softvos-choose-us" className='hrm-img' />
    </div>
  </div>
}

<div className='row foo'>
  <Footer />
</div>
    </div>
  )
}

export default Service
