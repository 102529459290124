import React, { useEffect, useState } from 'react';
import "./blogs.css"
import Footer from '../footer/Footer';
import { Parallax } from 'react-parallax'
import BlogImg from "../../img/blogs.jpg"

// Function to calculate read time
const calculateReadTime = (text, wordsPerMinute = 200) => {
  const wordCount = text.split(/\s+/).filter((word) => word).length;
  const readTime = Math.ceil(wordCount / wordsPerMinute);
  return readTime;
};

const SinglePost = () => {
  const [readTime, setReadTime] = useState(null);

  useEffect(() => {
    // Grabbing the content of the blog post automatically
    const blogContentElement = document.querySelector('.time-calculator');
    if (blogContentElement) {
      const blogText = blogContentElement.innerText || blogContentElement.textContent;
      const time = calculateReadTime(blogText);
      setReadTime(time);
    }
  }, []); // Empty dependency array ensures this runs once on component mount

  return (
    <div className='time-calculator'>

<Parallax bgImage={BlogImg} strength={500} style={{marginTop:'5%'}}>
    <div className='row'>
      <div className='col-md-12'>
        <h1 className='blog-page-heading'>Blogs</h1>
      </div>
    </div>
    </Parallax>

      <div className='row'>
        <div className='col-md-12'>
          <h1 className='sp-head'>This Is The Main Title</h1>
          <br />
          <br />
          <h3 className='sp-author'>Author :- ADDNEX</h3>
          <h3 className='sp-date'>22/July/2024</h3>
          <h6> {readTime !== null ? (
          <h6 className='sp-estimated-time'>Estimated Read Time: {readTime} minute{readTime > 1 ? 's' : ''}</h6>
        ) : (
          <h6>Loading content and calculating read time...</h6>
        )}</h6>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h2 className='sp-title'>This Is Title</h2>
        </div>

        <div className='col-md-8'>
          <p className="padds" style={{marginTop:'10%'}}>
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          </p>
        </div>
        <div className='col-md-4'>
          <img src='https://i.ibb.co/vHLcf4Z/pexels-tima-miroshnichenko-5453811.jpg' className="spi-img" alt='' />
        </div>

        <div className='col-md-6' style={{ color: 'blue' }}>
          {/* Blue text */}
          <p className="padds">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          </p>
        </div>

        <div className='col-md-6'>
          <p className="padds">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          </p>
        </div>

        <div className='col-md-12'>
          <p className="padds">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          </p>

          <br />

          <p className="padds">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          </p>
        </div>
      </div>

      <h1 className='sp-head'style={{color:'darkblue'}}>The End.....</h1>


      <div className='row foo'>
        <Footer />
      </div>

    </div>
  );
};

export default SinglePost;
