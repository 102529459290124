import React from 'react'
import Slider from "react-slick";
import "../brandslider/slider.css";
import "../testi/testi.css"


const Testi = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 3,
      };
      return (
        <div style={{padding:'5%'}}>
          <Slider {...settings}>
           <div className='small-card'>
            <p className='smallcard-para'>
            Addnex provides exceptional web services that boost functionality, aesthetics, and revenue.
             Highly recommend for top-quality web solutions.
             <br />     - <span className='foundersNme'>Ravi Sharma</span>
            </p>
           </div>

           <div className='small-card'>
            <p className='smallcard-para'>
            Addnex delivers outstanding web development with expert guidance, personalized attention, and timely delivery. Highly recommend for reliable and competent web services. 
            <br />     - <span className='foundersNme'>Aishwarya Verma</span>

            </p>
           </div>


           <div className='small-card'>
            <p className='smallcard-para'>
            Addnex is the best web development company with unmatched attention to detail, professionalism, and timely delivery. Highly recommend for top-quality web solutions.
            <br />     - <span className='foundersNme'>Arjun Singh</span>

             </p>
           </div>


           <div className='small-card'>
            <p className='smallcard-para'>
            Addnex transforms websites with expertise, responsiveness, and attention to detail into highly functional and visually appealing platforms. Top choice for web solutions.
            <br />     - <span className='foundersNme'>Carlos Rodriguez</span>

             </p>
           </div>


           <div className='small-card'>
            <p className='smallcard-para'>
            Addnex provides customized web solutions with a professional and reliable approach that exceeds business needs. Highly recommend for outstanding web development.
            <br />     - <span className='foundersNme'>Hiroshi Tanaka</span>

            </p>
           </div>


           <div className='small-card'>
            <p className='smallcard-para'>
            Addnex impressed me with their web services that turned my vision into a beautiful, functional website. Highly recommend for increased online presence. 
            <br />     - <span className='foundersNme'>Sarah Johnson</span>
</p>

           </div>
          </Slider>
        </div>
      )
}

export default Testi
