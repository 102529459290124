import React, { useState } from 'react'
import inf from "../../img/influencer.jpg"
import "./influ.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Dropdown from '../subcomponent/Dropdown'
import GeneralCard from '../subcomponent/generalcards/GeneralCard'
import Footer from '../footer/Footer'
const Influ = () => {


  return (
    <>
    <div className='row'>
     <div className='col-md-6 c-page-left'>
        <h1 className='c-page-head'>For <br />  Influencers</h1>
     </div>
     <div className='col-md-6'>
        <img src={inf} className='ip-banner-image' alt="banner for influencer page for addnex"/>
     </div>
    </div>
<br /><br />
<br /><br />
    <div className='row'>
        <div className='col-md-12'>
        <h1 class="influencer-typing-container">Elevate <span className='special-carac'>YourSelf</span> with a Custom Website!</h1>
        </div>
    </div>
    
    <div className='row col-mar'>
      <div className='col-md-12 '>
      <h2 className='inf-subhead-left'>Showcase Your Talent with a Modern, Personalized Website</h2>
      <br />
      <p className='inf-para-left'>Elevate your online presence with a customized portfolio website tailored to your unique style. Highlight your work, engage your audience, and build your brand with a professional, modern platform. Let your website be the foundation for growing your influence and showcasing your talent.</p>

      <br />
      <br />
      <br />
      <Link className="big-button" to="/contact">Get Started Today</Link>
      </div>
    </div>

<br />
<br />
<br />

    <div className='row col-mar'>
      <div className='col-md-6'>
        <Dropdown 
        title="Build Credibility and Trust"
        description="A professional website establishes your brand as credible and reliable, making a strong first impression on visitors. It shows you're serious about your work and helps build trust with potential clients or customers."
      />

      <Dropdown 
        title="Showcase Your Skills and Work"
        description="It provides a dedicated space to effectively present your portfolio, services, and achievements. This helps potential clients or employers easily understand what you offer and why they should choose you."
      /> 
    
      <Dropdown 
      title="Reach a Wider Audience"
      description="A website allows you to reach a global audience, expanding your market beyond local boundaries. This wider reach can attract more potential customers and increase your influence."
    /> 
    
    <Dropdown 
    title="Enhance  SEO"
    description="Optimizing your website for search engines improves your chances of appearing in search results. This increased visibility makes it easier for people to discover your services or products online."
  />
      </div>

      <div className='col-md-6 c-page-left'>
        <h1 className='c-page-head'>Why ?</h1>
     </div>
    </div>

    <br /><br />
 

    <div className='row col-mar'>
      <div className='col-md-12 c-page-left'>
      <h1 className='c-sub-page-head'>
          unlock your full
        </h1>
      <h1 className='c-sub-page-h'>Potential!</h1>
      </div>
    </div>

    <div className='row col-mar'>
      <GeneralCard title="Showcase Your Talent" para="Display your work, achievements, and unique style. Highlight your skills and projects to attract potential clients and collaborators." />
      <GeneralCard title="Grow Your Audience" para="Engage visitors with interactive features and social media integration. Build a loyal following by providing valuable content and connecting with your audience across platforms." />
      <GeneralCard title="Monetize Your Brand" para="Create opportunities for partnerships and sponsorships. Leverage your online presence to explore new revenue streams and build brand collaborations." />
    </div>

    <div className='row col-mar'>
      <div className='col-md-12'>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        src="https://player.vimeo.com/video/1005179266?autoplay=1&loop=1&muted=1&badge=0&autopause=0&player_id=0&app_id=58479"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title="infvid"
      ></iframe>
      <div className='text-over-video'>
      We consistently deliver excellence.
      </div>
    </div>
      </div>
    </div>

    <div className='row col-mar'>
      <div className='col-md-12'>
        <h1 className='c-sub-page-h'>What We Offer...!</h1>
      </div>
    </div>
    
    <div className='row col-mar'>
      <div className='col-md-6'>
        <h2 className='inf-uniqu-1'>Custom Design</h2>
        <h2 className='inf-uniqu-2'>SEO Optimization</h2>
      </div>
      <div className='col-md-6'>
        <h2 className='inf-uniqu-3'>Responsive & Mobile-Friendly</h2>
        <h2 className='inf-uniqu-4'>Easy Content Management</h2>
      </div>
    </div>
    
    <div className='row col-mar'>
    <div className='col-md-12'>
    <Link className="big-button" to="/contact">Get A Quote</Link>
    </div>
    </div>



    <div className='row foo'>
        <Footer />
      </div>
    </>
  )
}

export default Influ
