import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake , faGlobe,faShieldHalved} from '@fortawesome/free-solid-svg-icons'

const TrustSection = () => {
  return (
    <>
     
        <div className='col-md-4'>
          <div className='home-trust-section'>
        <FontAwesomeIcon className='home-trust-icons' icon={faHandshake} beatFade />
        <br /> <br/>
        <p className='home-trust-para'>We built our company on a foundation of unwavering trust, ensuring integrity, transparency, and reliability.</p>
          </div>                                                  
        </div>

        <div className='col-md-4'>
          <div className='home-trust-section'>
        <FontAwesomeIcon className='home-trust-icons' icon={faGlobe} flip />
        <br /> <br/>
        <p className='home-trust-para'>We have clients from around the globe showcasing their best innovations.</p>
          </div>                                                  
        </div>

        <div className='col-md-4'>
          <div className='home-trust-section'>
          <FontAwesomeIcon icon={faShieldHalved} spinPulse className='home-trust-icons'/>
          <br /><br />
            <p className='home-trust-para'>We built our company on a foundation of unwavering trust, ensuring integrity, transparency, and reliability.</p>
          </div>                                                  
        </div>
    </>
  )
}

export default TrustSection
