// imagelink.js

const imageLinks = {
    bannerImage: [
'https://i.ibb.co/KLbtvYG/banner.jpg'  ,
'https://i.ibb.co/tJRS0t9/Softvos.png' ,
'https://i.ibb.co/jvfRs3j/softvos-banner.jpg'

],
    section1Image: [
      'https://example.com/image1.jpg',
      'https://example.com/image2.jpg',
      // Add more images for section 1
    ],
    section2Image: [
      'https://example.com/image3.jpg',
      'https://example.com/image4.jpg',
      // Add more images for section 2
    ],
    // Add more sections and their respective image links as needed
  };
  export const lastBannerImage = imageLinks.bannerImage.slice(-1)[0];
  export default imageLinks;
  