import React from 'react'

const GeneralCard = ({title,para}) => {
  return (
    <>
    <div className='col-md-4 gc-main'>
    <div className='gc-container'>
        <h1 className='gc-head'>{title}</h1>
        <br />
        <p className='gc-para'>
        {para}
        </p>
    </div>    
    </div> 
    </>
  )
}

export default GeneralCard
