import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import "../cards/card.css"

const CardsOne = (props) => {
  return (
    <div className='col-md-3 new-bans'>
      <Link to={`/service/${props.id}`}  className='new-card-services'>
      <h4 className='product-para'>{props.name}</h4>
      <p className='card-minitext'>{props.shortDesc}</p>     
       </Link>
    </div>
  )
}

export default CardsOne
