import React from 'react';
import building from "../../img/building.jpg";
import "../home/home.css";
import { Parallax } from 'react-parallax'; // Import the Parallax component
import Card from '../cards/Card';
import { productData } from '../../asset';
import Bslider from '../brandslider/Bslider';
import known from '../../img/known.png';
import SoftvosUi from '../../img/softvos-ui.jpg'
import inf from '../../img/inf.jpg'
import softvosHrm from '../../img/softvos-hrm.jpg'
import Testi from '../testi/Testi';
import Footer from '../footer/Footer';
import Banner from './homeBanner/Banner';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import TrustSection from '../subcomponent/TrustSection';
import Entrepreneurs from '../subcomponent/Entrepreneurs';
import Contact from '../contact/Contact';
import CardsOne from '../cards/CardsOne';
const BackendImage = "https://i.ibb.co/dtnXvSL/backend.jpg";
const chooseUs = "https://i.ibb.co/6BJ7TXN/choose-us.jpg";


const Home = () => {
  return (
    <div>
      <div className='row bgg bgx'>
        <div className='col-md-12' style={{ justifyContent: 'center', textAlign: 'center' }}>
          <h1 className='home-para'>Best Software Development Services For Your Innovative Ideas.</h1>
          <p className='home-para-paragraph'>Reduce your business operation cost and optimize company’s productivity with Addnex Technologies & IT consulting services.</p>
          <Link className='info-button'>Inquire Us</Link>
        </div>
      </div>
      <br /><br />

      <div className='row home-trust-'>
      <TrustSection />
      </div>

      <br /><br />

      <div className='row home-entrepreneurs'>
      <h1 className='entre-head'>THE SOFTWARE DEVELOPMENT COMPANY THAT EMPOWERS NEW EMERGING ENTREPRENEURS !</h1>
<Entrepreneurs />
</div>

<div className='row' style={{ marginTop: '5%' }}>
        <div className='col-md-6 know'>
          <h1 className='smallheadings'> What Are We Known For</h1>
          <br />
          <p className='known-para'>
            We are known for creating fast and efficient websites at minimal cost by adopting a minimalist approach.
            Our key to success lies in simplicity, as we believe that a clutter-free design enhances user
            experience and improves website performance. By prioritizing function over form, we create websites that are both aesthetically pleasing and easy to navigate.
          </p>
          <br /><br />
          <Link className='know-button' to="/service/know-more">Know More</Link>
        </div>
        <div className='col-md-6'>
          <img src={known} className='known-img' style={{ marginTop: '10%' }} alt="" />
        </div>
      </div>



    <br /><br /><br />


    <Parallax bgImage={inf} strength={500} style={{marginTop:'5%'}}>
      <div className='row perSoftware' style={{textAlign:'centre'}}>
      <div className='col-md-12'>
        <h1 className='smallheadings' style={{color:'black'}}>For Influencers</h1>
          <br />
          <p className='perPara spec-para' style={{color:'Black'}}>
          At our UI design service, we specialize in creating exceptional user interfaces that captivate and engage. Our expert designers leverage cutting-edge techniques to ensure your website or application not only looks stunning but also is optimized for search engines. We understand the importance of designs that enhance user experience and drive organic traffic. With our UI design solutions, your online presence will stand out, leaving a lasting impression on both users and search engine algorithms."                    </p>
          <br /><br />
          <Link className='know-button' to="/influencer" >Know More</Link>
          <br /><br/>
        </div>
      </div>
          </Parallax>





    <br /><br /><br />

      <div className='row our-serv'>
        {productData.slice(0,8).map((dat) => (
          <CardsOne name={dat.name} id={dat.id} alink={dat.alink} imgsrc={dat.imgsrc} leftImg={dat.leftImg} shortDesc={dat.shortDesc} key={dat.id} />
        ))}
      </div>

      <div className='row perSoftware'>
      <div className='col-md-6'>
        <h1 className='smallheadings'>Why Choose Addnex</h1>
          <br />
          <p className='perPara'>
          Choosing us means gaining access to our exceptional teams of highly skilled individuals who are at the forefront of their respective fields. We take pride in offering the best facilities and resources to support your needs. Our commitment to excellence ensures that we provide unparalleled expertise and innovation. When you choose us, you're selecting a partner dedicated to delivering top-tier solutions and fostering success. Join us in realizing your goals and experiencing the difference of working with the best.</p>
          <br /><br />
          <Link className='know-button' to="/service/Choose-Us">Know More</Link>
        </div>
        <div className='col-md-6'>
          <img src={chooseUs} alt="softvos-choose-us" className='hrm-img' />
        </div>
      </div>



      <div className='row' style={{ marginTop: '5%' }}>
        <div className='col-md-12'>
          <h1 className='smallheadings'>Our projects</h1>
          <br />
          <Bslider />
        </div>
      </div>

      

      <div className='row perSoftware'>
        <div className='col-md-6'>
          <img src='https://i.ibb.co/g7m6nNw/personalized-software.png' alt="softvos-personalized-software" className='perSoftware-img' />
        </div>
        <div className='col-md-6'>
        <h1 className='smallheadings'>Personalized Softwares</h1>
          <br />
          <p className='perPara'>
          We offer customized software solutions at highly competitive prices, encompassing all the essential features while allowing extensive customization to align precisely with your needs. Our commitment to excellence ensures that we consistently meet or even exceed project deadlines. We specialize in creating flexible and lightning-fast backend systems, enhancing user interaction with a seamless UI. Additionally, we provide comprehensive admin dashboards for efficient management
          </p>
          <br /><br />
          <Link className='know-button'to="/service/personalized-software">Know More</Link>
        </div>
      </div>

      <div className='row perSoftware'>
      <div className='col-md-6'>
        <h1 className='smallheadings'>Recruit Leaders &
High-Performing Innovative Teams
Using Addnex</h1>
          <br />
          <p className='perPara'>
          "As a top HR services provider, we excel in offering tailored solutions, including talent acquisition, onboarding, talent management, and employee engagement. With a seasoned HR team, we prepare your workforce for the modern workplace. Our adaptable services align with your evolving needs, ensuring streamlined, compliant HR processes. Partner with us to optimize your workforce and focus on core business, while we manage HR efficiently, enhancing your SEO and overall business growth."          </p>
          <br /><br />
          <Link className='know-button'to="/service/human-resource" >Know More</Link>
        </div>
        <div className='col-md-6'>
          <img src={softvosHrm} alt="softvos-human-resource" className='hrm-img' />
        </div>
      </div>
        

<br /><br/>
          <Parallax bgImage={SoftvosUi} strength={500} style={{marginTop:'5%'}}>
      <div className='row perSoftware' style={{textAlign:'centre'}}>
      <div className='col-md-12'>
        <h1 className='smallheadings' style={{color:'white'}}>Crafting Exceptional User Interfaces</h1>
          <br />
          <p className='perPara spec-para' style={{color:'white'}}>
          At our UI design service, we specialize in creating exceptional user interfaces that captivate and engage. Our expert designers leverage cutting-edge techniques to ensure your website or application not only looks stunning but also is optimized for search engines. We understand the importance of designs that enhance user experience and drive organic traffic. With our UI design solutions, your online presence will stand out, leaving a lasting impression on both users and search engine algorithms."                    </p>
          <br /><br />
          <Link className='know-button' to="/service/ui" >Know More</Link>
          <br /><br/>
        </div>
      </div>
          </Parallax>
          <br />


          <div className='row perSoftware'>
        
      <div className='col-md-6'>
        <h1 className='smallheadings'>Efficient Backend Development Solutions At Addnex</h1>
          <br />
          <p className='perPara'>
          "At Addnex, we excel in providing efficient backend development and project structuring solutions. Our experts leverage cutting-edge technologies to create scalable and user-centric systems. Our meticulous project structuring ensures efficient code management, leading to faster load times and enhanced performance. Partner with us to gain a competitive edge in the digital landscape." </p>
          <br /><br />
          <Link className='know-button' to="/service/backend-development">Know More</Link>
        </div>
        <div className='col-md-6'>
          <img src={BackendImage} alt="softvos-backend-development" className='hrm-img' />
        </div>
      </div>
<br /><br />
       <div className='row'>
        <h1 style ={{textAlign:'center', fontWeight:'bolder', color:'#2a2a90'}}>Contact Us</h1>
        <Contact />
       </div>




      <div className='row ja' style={{ marginTop: '5%' }}>
        <div className='col-md-12'>
          <h1 className='smallheadings'>Customer Experiences</h1>
          <br />
          <Testi />
        </div>
      </div>

      <div className='row foo'>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
