import React from 'react'
import ourservices from '../../img/ourservices.png'
import marketing from '../../img/marketing.png'
import web from '../../img/web.png'
import app from '../../img/app.png'
import {Link} from 'react-router-dom'
import "./services.css"
import confrence from '../../img/confrence.png'
import Bslider from '../brandslider/Bslider'
import Footer from '../footer/Footer'
import Banner from '../banner/Banner'
const Services = () => {
  return (
    <div>
      <div className='row head new'>
        
        <div className='col-md-6'>
            <div className='services'>
                <h1>Our Services</h1>
                    <p className='services-main para'>
                    Welcome to Softvos, a leading provider of web services that offers a comprehensive range of solutions for businesses and individuals. 
                    Our website development, hosting, and maintenance services are designed to help you create a professional and 
                    effective online presence that drives results. With our team of experts dedicated to delivering high-quality services and support, you can be confident that your website will be up and running smoothly 24/7. 
                    Our customized solutions, including e-commerce functionality, mobile-responsive design, and search engine optimization, are tailored to meet your unique needs and requirements. 
                    Choose Softvos for all your web services needs and experience the difference our expertise can make. Contact us today to learn more about how we can help you achieve your online goals.
                    </p>
            </div>
        </div>
        <div className='col-md-6'>
            <img src={ourservices} alt="" className='services-img' />
        </div>
      </div>

      <div className='row head'>
      <div className='col-md-6'>
            <img src={marketing} alt="" className='services-img' />
        </div>
        <div className='col-md-6'>
            <div className='services'>
                <h1>Marketing</h1>
                    <p className='services-main para'>
                    Softvos offers digital marketing services to help businesses boost their online grow headth. Our team of experts
                     creates custom strategies to drive traffic, generate leads, and increase conversions.
                     Our services include SEO, social media, email campaigns, and content creation, among others.
                     With our results-driven solutions, you can achieve your marketing goals and stand out in a crow headded online space. 
                     Choose Softvos for effective and tailored digital marketing services</p>
                     <br />
                     <Link className="know-button" to="/service/digital-marketing">Learn More</Link>
            </div>
        </div>
      </div>

      <div className='row head new'>
        <div className='col-md-6'>
            <div className='services'>
                <h1>Ui / Ux</h1>
                    <p className='services-main para'>
                    Softvos is your go-to destination for the best UI and UX designing services. Our team of experts specializes in creating 
                    visually appealing and user-friendly designs that enhance user experience and engagement. We combine aesthetics with functionality 
                    to deliver designs that not only look great but also offer seamless navigation and usability. From wireframes to prototypes, we work 
                    closely with our clients to ensure that every design element is customized to their unique requirements. Choose Softvos for UI and UX 
                    designs that stand out and make a lasting impression.</p>
                    <br />
                     <Link className="know-button" to="/service/ui">Learn More</Link>
            </div>
        </div>
        <div className='col-md-6'>
            <img src={confrence} alt="" className='services-img' />
        </div>
      </div>

      <div className='row head'>
        <div className='col-md-6'>
            <img src={web} alt="" className='services-img' />
        </div>
        <div className='col-md-6'>
            <div className='services'>
                <h1>Web Development</h1>
                    <p className='services-main para'>
                    At Softvos, we provide exceptional website development services that help businesses 
                    establish a strong online presence. Our team of skilled developers creates customized 
                    and responsive websites that deliver seamless user experiences. We prioritize functionality
                    and aesthetics to ensure that your website meets your unique needs and impresses your audience. 
                    Whether you need a simple website or a complex web application, we have the expertise to deliver 
                    exceptional results. Choose Softvos for the best website development services and take your online
                    presence to the next level.</p>
                    <br />
                     <Link className="know-button" to="/service/web-development">Learn More</Link>
            </div>
        </div>
      </div>


      <div className='row head new'>
        <div className='col-md-6'>
            <div className='services'>
                <h1>App Development</h1>
                    <p className='services-main para'>
                    Softvos provides top-notch app development services to businesses and individuals. Our team of experienced developers utilizes the latest technologies and industry best practices to deliver high-quality and user-friendly mobile applications. Whether you need a simple app or a complex enterprise-level solution, we have the expertise to bring your ideas to life. Choose Softvos for reliable and efficient app development services that meet your unique needs and requirements. Contact us today to discuss your app development project.</p>
                    <br />
                     <Link className="know-button" to="app-development">Learn More</Link>
            </div>
        </div>
        <div className='col-md-6'>
            <img src={app} alt="" className='services-img' />
        </div>
      </div>

      <div className='row head' style={{marginTop:'5%'}}>
  <div className='col-md-12'>
<h1 className='smallheadings'>Our projects</h1>
<br />
<Bslider />
</div>
</div>

<div className='row foo'>
  <Footer />
</div>

    </div>
  )
}

export default Services
