import React, { useState } from 'react'

const Dropdown = ({ title, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  return (
    <>
    {/* Main text with click handler to toggle dropdown */}
    <div onClick={toggleDropdown} style={{ cursor: 'pointer', padding: '10px', border: '1px solid #0d0d31', borderRadius: '5px', marginBottom: '10px' }}>
    <h3>
      {title} <span style={{ float: 'right' }}>{isOpen ? '▲' : '▼'}</span>
    </h3>
  </div>

  {/* Dropdown content that shows or hides based on the state */}
  {isOpen && (
    <div style={{ padding: '10px', border: '1px solid #0d0d31', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
      <p>{description}</p>
    </div>
  )}
  </>
  )
}

export default Dropdown
