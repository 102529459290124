import React from 'react'
import "./App.css";
import Navbar from './components/navbar/Navbar'
import Home from './components/home/Home'
import About from './components/aboutus/About'
import Footer from './components/footer/Footer';
import Service from './components/service/Service';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';
import pageNotFound from './components/pageNotFound';
import Entra from './components/entra/Entra';
import ContactPage from './components/pages/ContactPage';
import Influ from './components/influencer/Influ';
import Blogs from './components/blogs/Blogs';
import SinglePost from './components/blogs/SinglePost';

const App = () => {
  return (
    <div>
      <div className='container-fluid'>
          <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About}/>              
              <Route path="/our-services" component={Services} />
              <Route path="/service/:id" component={Service} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/shop" component={Entra} />
              <Route path="/influencer" component={Influ}/>
              <Route path="/blog" component={Blogs}/>
              <Route path="/single-post" component={SinglePost}/>
              </Switch>          
      </div>
    </div>
  )
}

export default App
