import React, { useState } from 'react';
import './web.css';
import { Link } from 'react-router-dom';
import NewImg from '../../img/ss1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple } from '@fortawesome/free-solid-svg-icons'

const WebProducts = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <div className='col-md-4'>
        <div className='shop-card'>
          <img src={props.image} alt={props.alt} className='shop-item-image' />
          <h1 className='shop-product-name'>{props.name}</h1>
          <br />
          <Link to="#" className="shop-explore-button" onClick={openModal}>
            Explore
          </Link>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay active" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="modal-close" onClick={closeModal}>&times;</span>
            <h2>Name: {props.name}</h2>
            <p>Description: {props.description}</p>
            <p>Price: {props.price}</p>
            <p>
  {props.price_type === 'static' ? "" : (
    <> <FontAwesomeIcon icon={faChartSimple} /></>
  )}
</p>
            <p>Features: </p>
            <ul>
              {Object.entries(props.features).map(([key, value]) => (
                <li key={key}>{key.replace(/_/g, ' ')}: {value}</li>
              ))}
            </ul>
            <Link to="/contact" className="shop-explore-button">
            Enquiry
          </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default WebProducts;
