import React , {useState} from 'react'
import "../contact/contact.css"
import Footer from '../footer/Footer'
import Banner from '../banner/Banner';
const chooseUs = "https://i.ibb.co/6BJ7TXN/choose-us.jpg";
const Contact = () => {
 const [userData, setUserData] = useState(
  {
    name:"" , email:"", mobile:"", subject:""
  }
 )
 let name , value
 const data=(e)=>{
name = e.target.name
value = e.target.value
setUserData({...userData,[name]: value});
 }
 const getData = async(e)=>{
  const {name , email, mobile, subject} = userData;
  e.preventDefault();
  const option = {
    method:'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name , email, mobile, subject
    })
  }
  const res = await fetch('https://softvos-b0b94-default-rtdb.firebaseio.com/UserData.json',
  option
  )
  if(res){
    alert("sent")
    console.log(res)
  }else{
    alert("Error occured")
  }
 }
  return (

    <div className='row'>
          

      <div className='col-md-6 contact'> 
      <form method='POST'>
  <div className="form-group">
    <label for="exampleInputEmail1">Name</label>
    <input type="text" className="form-control write" id="exampleInputEmail1"  value={userData.name} aria-describedby="emailHelp" name='name' placeholder="Name"  autoComplete='off' required onChange={data}/>
    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div className="form-group">
    <label for="exampleInputPassword1">Mobile No.</label>
    <input type="text" name="mobile" className="form-control write" value={userData.mobile} id="exampleInputPassword1" placeholder="Mobile No." autoComplete='off' required onChange={data} />
  </div>

  <div className="form-group">
    <label for="exampleInputPassword1">Email</label>
    <input type="email" name="email" className="form-control write" value={userData.email} id="exampleInputPassword1" placeholder="Email"  autoComplete='off' required onChange={data}/>
  </div>

  <div className="form-group">
    <label for="exampleInputPassword1">Subject </label>
    <input type="text" name="subject" className="form-control write" value={userData.subject} id="exampleInputPassword1" placeholder="Subject" autoComplete='off' required onChange={data} />
  </div>

  <button type="submit" className="contact-button" onClick={getData}>Submit</button>
</form>  
     </div>
     <div className='col-md-6'>
      <img src = "https://i.ibb.co/9ycT2MC/undraw-Personal-email-re-4lx7.png" alt="Addnex-contact-us" className='contact-image' />
     </div>
     
    </div>
  )
}

export default Contact
