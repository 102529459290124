import React from 'react'
const bannerImage = "https://i.ibb.co/BysLp4q/pexels-wendy-wei-1555900.jpg"
const Banner = () => {
  return (
    <div>
      <div className='row'>
        <img src={bannerImage} alt="softvos-banner-image" className='softvos-banner-image' />
      </div>
    </div>
  )
}

export default Banner
