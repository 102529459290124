import React from 'react'
import Slider from "react-slick";
import "../brandslider/slider.css"

const Bslider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesToShow: 2,
  };
  return (
    <div style={{padding:'5%'}}>
      <Slider {...settings}>
        <img src="https://i.ibb.co/nQMLyPL/slider6.png" alt=""  className='sliderimg'/>
        <img src="https://i.ibb.co/VjwJ7Sv/slider5.png" alt="" className='sliderimg'/>
        <img src="https://i.ibb.co/Swkp511/slider4.png" alt="" className='sliderimg'/>
        {/*<img src="https://i.ibb.co/JrY8rzq/slider3.png" alt="" className='sliderimg'/>*/}
        <img src="https://i.ibb.co/NtpJh2j/slider2.png" alt="" className='sliderimg'/>
        <img src="https://i.ibb.co/KsrN6CV/slider1.png" alt="" className='sliderimg'/>

      </Slider>
    </div>
  )
}

export default Bslider
