 const ShopData = [
    
    {
        id:"landing-page",
        name:"Landing Page",
        description:"single page website",
        price:"1999",
        price_type:"static",
        features:{
            "domain":"excluded",
            "hosting":"free",
            "language":"react/next",
            "responsive_design": "yes",
            "contact_form": "custom",
            "number_of_pages":"one"
        },
        image:"https://i.ibb.co/b31qk32/ss1.png",
        alt:"addnex single landing page site image",
    },

    {
        id:"service-website",
        name:"Multi Page Website",
        description:"multi page website",
        price:"5999",
        price_type:"dynamic",
        features:{
            "domain":"excluded",
            "hosting":"free/depending upon the project size",
            "language":"react/next",
            "responsive-design":"yes",
            "contact_form":"custom",
            "number_of_pages":"5",
        },
        image:"https://i.ibb.co/YppfPTK/Screenshot-from-2024-07-28-18-03-37.png",
        alt:"multiple page website"
    },

    {
        id:"shopify-store",
        name:"Shopify Store",
        description:"shopify store",
        price:"12999",
        price_type:"dynamic",
        features:{
            "domain":"excluded",
            "hosting":"charges apply",
            "language":"shoifie's liquid",
            "responsive_design":"yes",
            "contact_form":"custom",
            "number_of_pages":"7"
        },
        image:"https://i.ibb.co/cFKTkS2/Screenshot-from-2024-07-28-18-01-42.png",
        alt:"addnex shopify store image"
    },

    {
        id:"graphics",
        name:"Graphics Store",
        description:"design your ui / ux / logo by professionals",
        price:"599",
        price_type:"dynamic",
        features:{
            "ai_used":"none",
            "designed_by":"specialists"
         },
         image:"https://i.ibb.co/N9zCNBh/Screenshot-from-2024-07-28-18-05-28.png",
         alt:"addnex graphic designing services"
    },

    {
        id:"management ",
        name:"Project Management",
        description:"manage projects on your behalf",
        price:"999",
        price_type:"dynamic",
        features:{
            "service_type":"ticket based"
        },
        image:"https://i.ibb.co/88sQ3Rc/Screenshot-from-2024-07-28-18-08-19.png",
        alt:"product image website"
    }
];

export default ShopData;