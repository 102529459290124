import React from 'react'
import "../footer/footer.css"
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faLocation } from '@fortawesome/free-solid-svg-icons'
const Footer = () => {
  return (
    <>
    <div className=' row footerWrapper'>
      <div className='col-md-6 footer-section'>
         <h1 className='brand-navbar'>Addnex</h1>
         <p>Solutions For A Digital World</p>
      </div>

      <div className='col-md-6 footer-section'>
      <h5 className='footer-right-section'>"Inspiring Solutions for Tomorrow's Success
Empowering Innovations, Driving Excellence
Where Software Meets Potential, Limitless Possibilities"</h5>
<br />
<p className='footer-contact-details'>
<FontAwesomeIcon icon={faPhone} />: +918824991267 
&nbsp; &nbsp;

<FontAwesomeIcon icon={faLocation} /> Office: 28 malviya nagar, jaipur  (Rajasthan)  

</p>
      </div>
    </div>
    </>
  )
}

export default Footer
